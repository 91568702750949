import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Home from "./routes/Home";
import V1 from "./routes/v1";
import V2 from "./routes/v2";
import V3 from "./routes/v3";
import V4 from "./routes/v4";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "v1",
    element: <V1 />,
  },
  {
    path: "v2",
    element: <V2 />,
  },
  {
    path: "v3",
    element: <V3 />,
  },
  {
    path: "v4",
    element: <V4 />,
  },
]);

root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
