import { Unity, useUnityContext } from "react-unity-webgl";
import "../App.css";

const V3 = () => {
  const { unityProvider } = useUnityContext({
    loaderUrl: "builds/v3/build.loader.js",
    dataUrl: "builds/v3/build.data",
    frameworkUrl: "builds/v3/build.framework.js",
    codeUrl: "builds/v3/build.wasm",
  });

  return (
    <Unity
      unityProvider={unityProvider}
      devicePixelRatio={window.devicePixelRatio}
    />
  );
};

export default V3;
