import { Unity, useUnityContext } from "react-unity-webgl";
import "../App.css";
import { Link } from "react-router-dom";

const Home = () => {
  const { unityProvider } = useUnityContext({
    loaderUrl: "builds/v1/build.loader.js",
    dataUrl: "builds/v1/build.data",
    frameworkUrl: "builds/v1/build.framework.js",
    codeUrl: "builds/v1/build.wasm",
  });

  return (
    <div className="main">
      <h1>Crash game</h1>

<div className="column">
      <Link to="v1">No Graph, Big Number</Link>
      <Link to="v2">Tap to start, tap to cashout</Link>
      <Link to="v3">Hold to start, release to cashout</Link>
      <Link to="v4">Bet coins</Link>
      </div>
    </div>
  );
};

export default Home;
